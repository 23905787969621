import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import pic1 from '../../images/deep2.jpg'
import pic2 from '../../images/deep3.jpg'
import pic3 from '../../images/deep4.jpg'
import pic4 from '../../images/deep5.jpg'
import video1 from '../../videos/deep.mp4'

export default class TraceSource extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={1}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='深度视频分析' />
        <div className='core-tech-wrap deep-wrap'>
          <div className='main-img'></div>
          <div className='main-con'>
            <h2>深度视频分析概述</h2>
            <p className='description-text'>
              易见纹语科技基于全球领先的Al图像视频分析技术，打造了无人值守的视频影像监控、分析和剪辑系统。该系统利用农产品在种植/生产、仓储、
              流通中的监控视频影像，利用自研的高效视频分析引擎结合人工智能视觉分析技术，完成穿着/陈设合规性检测、生产情况检测、异常事件检测、违规
              行为检测、工作流程规范检测等业务需求，配合自动剪辑系统自动生成剪辑视频，用于产品溯源、事件存证或责任认定，解决了传统监测手段人工成
              本高、时效性差、无法量化的难题。
            </p>
          </div>
        </div>
        <div
          className='core-tech-wrap deep-wrap'
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <div className='main-con'>
            <h2>技术优势及特色</h2>
            <div className='speical-box'>
              <div className='speical-list'>
                <div className='speical-title'>视频分析内容</div>
                <ul className='speical-ul'>
                  <li>
                    基于人脸识别的人员身份识别<div className='radius'></div>
                  </li>
                  <li>
                    人员穿着合规性检测，如是否穿戴工作装、工作帽等
                    <div className='radius'></div>
                  </li>
                  <li>
                    生产工具放置位置合规性检测，如垃圾桶是否放在特定区域
                    <div className='radius'></div>
                  </li>
                  <li>
                    种植/生产情况监测（开工、停工），生产区域监测、工人人数统计
                    <div className='radius'></div>
                  </li>
                  <li>
                    仓储出入库事件检测<div className='radius'></div>
                  </li>
                  <li>
                    产品运输的上下车事件检测<div className='radius'></div>
                  </li>
                  <li>
                    异常事件检测,如种植地/车间/仓库是否有人员入侵、是否有冒烟/起火现象
                    <div className='radius'></div>
                  </li>
                  <li>
                    违规行为检测，如人员跨越禁入区域，非授权开启设备等
                    <div className='radius'></div>
                  </li>
                  <li>
                    工作流程规范，如产品生产的工序是否缺失等
                    <div className='radius'></div>
                  </li>
                </ul>
              </div>
              <div className='speical-list'>
                <div className='speical-title'>算法优势</div>
                <ul className='speical-ul'>
                  <li>
                    <span className='speical-weight'>精准：</span>
                    使用当前最新的图像/视频分析算法，以实现高精度的图像分类、目
                    标检测、目标跟踪、人脸识别、动作识别等模块化任务。通过对业务需求的
                    拆分，将上述算法模块有机组合，最终完成高精度的视频分析结果。针对应
                    用场景差异，优化算法模块的网络结构，以完成小目标检测、细粒度特征分
                    类、精细动作识别等特殊任务需求。
                    <div className='radius'></div>
                  </li>
                  <li>
                    <span className='speical-weight'>安全：</span>
                    可根据需求提供视频内隐私图像自动遮掩功能，以及史辑视频人工复
                    核发布流程，保证数据的安全性。
                    <div className='radius'></div>
                  </li>
                  <li>
                    <span className='speical-weight'>高效：</span>
                    自研的视频分析引擎基于GPU高效视频解码、NVIDIA TensorRT®
                    效推理引擎、NVIDIA
                    Triton流处理微服务、以及基于K8s容器云的弹性扩
                    展能力，支持高达单服务器48路实时视频处理能力。
                    <div className='radius'></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='core-tech-wrap deep-wrap'>
          <img src={pic1} />
        </div>
        <div
          className='core-tech-wrap deep-wrap'
          style={{ backgroundColor: '#f5f5f5' }}
        >
          <div className='main-con'>
            <h2>技术应用</h2>
            <div className='deep-tec-box'>
              <div className='deep-dec-list'>
                <div className='deep-dec-pic'>
                  <img src={pic2} />
                </div>
                <div className='deep-dec-content'>
                  <div className='deep-dec-content-title'>
                    普洱茶种植仓储环节监控
                  </div>
                  <div className='deep-dec-content-subtitle'>
                    利用AI视助分析，对普洱古树茶种植基地的茶叶采收作业情况进行监控
                  </div>
                  <div className='deep-dec-content-text'>
                    茶园的作业情况分析（采茶中/未采茶）
                  </div>
                  <div className='deep-dec-content-text'>
                    采茶工人敷、作业时间分析
                  </div>
                </div>
              </div>
              <div className='deep-dec-list'>
                <div className='deep-dec-pic'>
                  <img src={pic3} />
                </div>
                <div className='deep-dec-content'>
                  <div className='deep-dec-content-title'>
                    仓库货物出入库视频自动剪辑
                  </div>
                  <div className='deep-dec-content-subtitle'>
                    利用AR嫁分析，对仓库货物的进出库作业进行检测及自动视频剪辑
                  </div>
                  <div className='deep-dec-content-text'>
                    仓管人员作业合规性检测
                  </div>
                  <div className='deep-dec-content-text'>
                    出入库事卜检测，及出入库视频自动剪辑
                  </div>
                  <div className='deep-dec-content-text'>
                    出入库路径多摄像头视频自动拼接
                  </div>
                  <div className='deep-dec-content-text'>货主信息AR展示</div>
                  <div className='deep-dec-content-text'>
                    非货主货坳隐私遮蔽
                  </div>
                  <div className='deep-dec-content-text'>
                    仓库异常情况监制（入侵、失火）
                  </div>
                </div>
              </div>
              <div className='deep-dec-list'>
                <div className='deep-dec-pic'>
                  <video className='deep-video-box' poster={pic4} controls>
                    <source src={video1} type='video/mp4' />
                  </video>
                </div>
                <div className='deep-dec-content'>
                  <div className='deep-dec-content-title'>
                    普洱茶分析生产加工过程
                  </div>
                  <div className='deep-dec-content-subtitle'>
                    利用AI视频分析，农产品加工工厂打造&Prime;可视化&Prime;的溯源和生产监管能力
                  </div>
                  <div className='deep-dec-content-text'>
                    生产状况分析（在生产侏生产）
                  </div>
                  <div className='deep-dec-content-text'>
                    生产区域、生产步骤检测及自动视频剪辑
                  </div>
                  <div className='deep-dec-content-text'>
                    员工穿着合规性检测
                  </div>
                  <div className='deep-dec-content-text'>生产违规行为检测</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
